import client from "./_client";

const endpointV1 = `/v1/bundles`;

//get bundleData
export const getbundledetails = (bundleId) => {
  return client.get(endpointV1 + `/creator-bundle?bundleId=${bundleId}`);
};

//create bundle structure
export const createbundle = (details) => {
  return client.post(endpointV1 + "/", details);
};

//update bundle structure
export const updateBundle = (details) => {
  return client.patch(endpointV1 + "/", details);
};

//for data in landing-page
export const addbundledetails = (details, bundleId) => {
  return client.patch(
    endpointV1 + `/bundle-details?bundleId=${bundleId}`,
    details
  );
};

//for curriculum -> addind courses in the bundle
export const addcoursetobundle = (details, bundleId) => {
  console.log({ details });

  return client.patch(endpointV1 + `/course?bundleId=${bundleId}`, details);
};

//add price of bundle
export const addBundlePrice = (details) => {
  return client.patch(endpointV1 + `/price`, details);
};

//update order of courses
export const updateBundleOrder = (details, bundleId) => {
  return client.patch(
    endpointV1 + `/reorder-course?bundleId=${bundleId}`,
    details
  );
};

//add email
export const addBundleEmails = (details) => {
  return client.post(endpointV1 + `/email`, details);
};

//update email
export const updateBundleEmails = (details) => {
  return client.patch(endpointV1 + `/email`, details);
};

//publish bundle
export const publishBundle = (bundleId) => {
  return client.patch(endpointV1 + `/publish?bundleId=${bundleId}`);
};

//getmy bundles
export const myBundles = (p = 1, l = 4) => {
  return client.get(endpointV1 + `/my?page=${p}&limit=${l}`);
};

//delete a bundle
export const deleteBundle = (bundleId) =>
  client.delete(endpointV1 + `?bundleId=${bundleId}`);

//delete course from bundle
export const deleteCourseFromBundle = (bundleId, courseId) =>
  client.delete(
    endpointV1 + `/course?bundleId=${bundleId}&courseId=${courseId}`
  );

//edit course in bundle
export const editCourseInBundle = (bundleId, courseData, index) =>
  client.patch(
    endpointV1 + `/edit-course?bundleId=${bundleId}&index=${index}`,
    courseData
  );
