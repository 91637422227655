import client from "./_client";

const endpointV1 = "/v1/orders";

export const createassignment = (details) => client.post(endpointV1, details);

export const createOrder = async (prizeInfo) =>
  client.post(endpointV1 + "/", prizeInfo);

export const getOrders = async () => client.get(endpointV1 + "/");

export const getSingleOrder = async (orderId) =>
  client.get(endpointV1 + `/get?orderId=${orderId}`);

export const updateOrder = async (order) =>
  client.patch(endpointV1 + "/", order);
