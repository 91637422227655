import client from "./_client";

const endpointV1 = "/v1/users";

export const login = (details) => client.post(endpointV1 + "/login", details);

export const googlesignin = (details) =>
  client.post(endpointV1 + "/google-signin", details);

export const register = (details) =>
  client.post(endpointV1 + "/signup", details);

export const resetpassword = (email) =>
  client.get(endpointV1 + `/resetpassword?email=${email}`);

export const team = (page, limit = 12) =>
  client.get(endpointV1 + `/team?page=${page}&limit=${limit}`);

export const getuser = (value, key) =>
  client.get(endpointV1 + `/getuser?${key}=${value}`);

export const getCertificatesAndCP = (value) =>
  client.get(endpointV1 + `/getcertificatesandcp?id=${value}`);

export const getTopLearners = () => client.get(endpointV1 + `/toplearners`);

export const getuserprofile = (id) =>
  client.get(endpointV1 + `/getuserprofile?id=${id}`);

export const getRecommendedUsers = () =>
  client.get(endpointV1 + `/recommended-profiles`);

export const getuserinsights = (id) =>
  client.get(endpointV1 + `/getuserinsights?id=${id}`);

export const popularcreators = (p = 1, l = 5) =>
  client.get(endpointV1 + `/famousinstructor?page=${p}&limit=${l}`);

export const gettopperformer = () => client.get(endpointV1 + `/topperformer`);

export const checkCreatorAccess = (email) =>
  client.get(endpointV1 + `/checkcreatoraccess?email=${email}`);

export const getsearchresult = (searchtext, filter, p, l = 8) => {
  let query = searchtext.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const response = client.get(
    endpointV1 +
      `/search?query=${encodeURIComponent(query)}&filter=${filter}&page=${p}&limit=${l}`
  );
  return response;
};

export const updateShowTour = (details) => {
  return client.patch(endpointV1 + "/tour", details);
};

export const updateInvitedUser = (details) => {
  return client.patch(endpointV1 + "/inviteduser", details);
};

export const banUser = () => {
  return client.patch(endpointV1 + "/ban-user");
};

export const generateOtp = (details) => {
  return client.post(endpointV1 + "/otp", details);
};

export const verifyOtp = (details) => {
  return client.put(endpointV1 + "/verify-otp", details);
};
