import client from "./_client";

const endpointV1 = `/v1/stages`;
const endpointV2 = `/v2/course-stages`;

export const createStage = (stageBody) => client.post(endpointV1, stageBody);

export const getCourseStages = ({ cid }) =>
  client.get(endpointV1 + `?courseId=${cid}`);

export const updateStage = ({ cid, sid, details }) =>
  client.patch(endpointV1 + `?courseId=${cid}&stageId=${sid}`, details);

export const deleteStage = ({ cid, sid }) =>
  client.delete(endpointV1 + `?courseId=${cid}&stageId=${sid}`);

// v2 api

export const getStageVideos = ({ cid, stageNum, page = 1, limit = 15 }) =>
  client.get(
    endpointV2 +
      `/videos?courseId=${cid}&stageNum=${stageNum}&page=${page}&limit=${limit}`
  );

export const addStageDetailsV2 = (details) => {
  return client.post(endpointV2 + "/", details);
};

export const addStageContentV2 = (details) => {
  return client.post(endpointV2 + `/content`, details);
};

export const getStageDetailsV2 = ({ cid, purpose }) => {
  return client.get(endpointV2 + `/?cid=${cid}&purpose=${purpose}`);
};

export const updateStageOrderV2 = (details) => {
  return client.patch(endpointV2 + `/order`, details);
};

export const updateContentOrderV2 = (details) => {
  return client.patch(endpointV2 + `/content-order`, details);
};

export const updateStageV2 = (details) => {
  return client.patch(endpointV2 + `/`, details);
};

export const updateStageContentV2 = (details) => {
  return client.patch(endpointV2 + `/content`, details);
};

export const deleteStageV2 = (stageId) => {
  return client.delete(endpointV2 + `/?id=${stageId}`);
};

export const deleteContentV2 = (stageId, entityId) => {
  return client.delete(endpointV2 + `/content?sid=${stageId}&eid=${entityId}`);
};

export const addStageEmailV2 = (details) => {
  return client.post(endpointV2 + `/email`, details);
};

export const getStageEmailV2 = (sid) => {
  return client.get(endpointV2 + `/email?sid=${sid}`);
};

export const deleteStageEmailV2 = ({ id }) => {
  return client.delete(endpointV2 + `/email?id=${id}`);
};

export const getStageArticleV2 = (articleTitle) => {
  return client.get(endpointV2 + `/article?articleTitle=${articleTitle}`);
};

export const getStageContentV2 = (seoTitle, type) => {
  return client.get(endpointV2 + `/content?seoTitle=${seoTitle}&type=${type}`);
};
