import client from "./_client";

const endpointV1 = `/v1/likes`;

export const addLike = (videoId, courseId) =>
  client.post(endpointV1, { videoId, courseId });

export const getMyReactions = () => client.get(endpointV1 + `/me`);

export const getLikedVideos = ({ userId, page, limit }) =>
  client.get(
    endpointV1 + `/videos?userId=${userId}&page=${page}&limit=${limit}`
  );

// ! Depricated API (Will be removed soon)
export const likeStatus = (videoId) =>
  client.get(endpointV1 + `/status?videoId=${videoId}`);

export const removeLike = (videoId) =>
  client.delete(endpointV1 + `?videoId=${videoId}`);
