export function addToLocalStorage(key, value) {
  if (typeof window === "undefined") return null;
  if (typeof value === "object") {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    localStorage.setItem(key, value);
  }
}

export function getFromLocalStorage(key) {
  if (typeof window === "undefined") return null;
  let value = localStorage.getItem(key);
  if (!value) {
    return null;
  }

  try {
    return JSON.parse(value);
  } catch (error) {
    return value;
  }
}

export function removeFromLocalStorage(key) {
  if (typeof window === "undefined") return null;
  localStorage.removeItem(key);
}
