import client from "./_client";

const endpointV1 = `/v1/others`;

export const category = (p = 1, l = 5) =>
  client.get(endpointV1 + `/categories?page=${p}&limit=${l}`);

export const checkSlug = (slug) =>
  client.get(endpointV1 + `/checkslug?slug=${slug}`);

export const carousals = () => client.get(endpointV1 + `/carousals`);

export const prizes = async () => client.get(endpointV1 + "/prizes");

export const prize = async (prizeId) =>
  client.get(endpointV1 + `/prize?prizeId=${prizeId}`);

export const iKitAuth = () => client.get(endpointV1 + "/ikit/auth");

export const removeImageFromIKit = (imageId) =>
  client.delete(endpointV1 + `/ikit/delete?imageId=${imageId}`);

export const addAlumni = (details) =>
  client.post(endpointV1 + `/alumni`, details);

export const takeAlumniConsent = (details) =>
  client.post(endpointV1 + `/alumni-consent`, details);

export const getProgram = () => client.get(endpointV1 + `/programs`);

export const getAlumnis = (program, p = 1, l = 5) =>
  client.get(
    endpointV1 +
      `/alumni?${program ? `filter=${program}&` : ""}page=${p}&limit=${l}`
  );
