const baseURL =
  process.env.NODE_ENV === "production"
    ? process.env.NEXT_PUBLIC_PROD_JOIN_BASEURL
    : process.env.NODE_ENV === "development"
      ? process.env.NEXT_PUBLIC_DEV_JOIN_BASEURL
      : process.env.NEXT_PUBLIC_TEST_JOIN_BASEURL;

const DEFAULT_TIMEOUT = 15000;

const customFetch = async (url, options = {}) => {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), DEFAULT_TIMEOUT);

  try {
    const config = {
      ...options,
      signal: controller.signal,
      headers: {
        "Content-Type": "application/json",
        ...options.headers,
      },
      credentials: "include",
    };

    const fullUrl = url.startsWith("http") ? url : `${baseURL}${url}`;
    const response = await fetch(fullUrl, config);

    let data;
    const responseType = options.responseType || "json"; // Default to 'json' if not specified

    switch (responseType) {
      case "blob":
        data = await response?.blob();
        break;
      case "text":
        data = await response?.text();
        break;
      case "arrayBuffer":
        data = await response?.arrayBuffer();
        break;
      case "formData":
        data = await response?.formData();
        break;
      case "json":
      default:
        data = await response?.json();
        break;
    }

    return {
      status: response.status,
      headers: response.headers,
      data,
    };
  } catch (error) {
    if (error.name === "AbortError") {
      throw new Error("Request timeout");
    }
    throw error;
  } finally {
    clearTimeout(timeoutId);
    controller.abort(); // Ensure controller is aborted
  }
};

// Wrapper for HTTP methods
const client = {
  get: (url, options) => customFetch(url, { ...options, method: "GET" }),
  post: (url, body, options) =>
    customFetch(url, {
      ...options,
      method: "POST",
      body: JSON.stringify(body),
    }),
  patch: (url, body, options) =>
    customFetch(url, {
      ...options,
      method: "PATCH",
      body: JSON.stringify(body),
    }),
  put: (url, body, options) =>
    customFetch(url, { ...options, method: "PUT", body: JSON.stringify(body) }),
  delete: (url, options) => customFetch(url, { ...options, method: "DELETE" }),
};

export default client;
