import client from "./_client";

const endpointV1 = "/v1/subscriptions";

//POST REQUEST

export const createsubscription = (data) =>
  client.post(endpointV1 + `/`, { ...data });

export const deleteToken = (token) =>
  client.delete(endpointV1 + `/token?token=${token}`);
