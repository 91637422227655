import client from "./_client";

const endpoint = "/v1/certificates";

export const getCertificate = async (certificateId) =>
  client.get(endpoint + `?id=${certificateId}`);

export const getCertificates = async () =>
  client.get(endpoint + "/getUserCertificates");

export const getCertificateByCourse = async (courseId) =>
  client.get(endpoint + `/getCertificateByCourse?courseId=${courseId}`);

export const createCertificatePdf = async ({ courseId }) =>
  client.post(endpoint + `?courseId=${courseId}`);

export const regenerateCertificatePdf = async ({ certificateId }) =>
  client.post(endpoint + `/regenerate?certificateId=${certificateId}`);

export const isValidCertificate = async (certificateId) =>
  client.post(endpoint + `/verify?id=${certificateId}`);

export const getUserCertificates = async ({ page = 1, limit = 3 }) =>
  client.get(endpoint + `/getUserCertificates?page=${page}&limit=${limit}`);
