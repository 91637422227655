import client from "./_client";

const endpointV1 = `/v1/network`;

export const followuser = (networkBody) =>
  client.post(endpointV1 + "/follow", networkBody);

export const getNetwork = ({ uid = null, page = 1, limit = 25 }) =>
  client.get(
    endpointV1 + `/followings?userId=${uid}&page=${page}&limit=${limit}`
  );

export const getAllNetwork = () => client.get(endpointV1 + `/all`);

export const getFollowers = ({ uid = null, page = 1, limit = 25 }) =>
  client.get(
    endpointV1 + `/followers?userId=${uid}&page=${page}&limit=${limit}`
  );

export const getFollowing = ({ uid = null, page = 1, limit = 25 }) =>
  client.get(
    endpointV1 + `/followings?userId=${uid}&page=${page}&limit=${limit}`
  );

export const getFollowerCount = () =>
  client.get(endpointV1 + `/followers/count`);

export const isFollowing = (uid) =>
  client.get(endpointV1 + `/is-following?userId=${uid}`);

export const removeFollowing = (uid) =>
  client.delete(endpointV1 + `/unfollow?networkId=${uid}`);
