import client from "./_client";

const endpointV1 = `/v1/courses`;
const endpointV2 = `/v2/courses`;

export const updatecourse = (id, details) =>
  client.patch(endpointV1 + `?id=${id}`, details);

export const getcourse = (id) => client.get(endpointV1 + `?id=${id}`);

export const getallcourse = (p = 1, l = 8) =>
  client.get(endpointV1 + `/all?page=${p}&limit=${l}`);

export const getfilteredcourse = (filter, p = 1, l = 10) =>
  client.get(endpointV1 + `/filter?filter=${filter}&page=${p}&limit=${l}`);

export const getUnwrapCourse = (p = 1, l = 1) => {
  return client.get(endpointV1 + `/unwrap?limit=${l}&page=${p}`);
};

export const getcoursesbyctg = (ctg, filter = "Popular", p = 1, l = 8) =>
  client.get(
    endpointV1 + `/ctg?ctg=${ctg}&filter=${filter}&page=${p}&limit=${l}`
  );
export const gettrendcourses = () => client.get(endpointV1 + `/trend`);

export const coursesbycreator = ({ id, username, p = 1, l = 4 }) =>
  client.get(
    endpointV1 +
      `/creator?${
        id ? `id=${id}` : `username=${username}`
      }&page=${p}&limit=${l}`
  );

export const mycourses = (filter = "all", p = 1, l = 4) =>
  client.get(endpointV1 + `/my?filter=${filter}&page=${p}&limit=${l}`);

export const getlatestbyfollowing = (p = 1, l = 10) =>
  client.get(endpointV1 + `/following?page=${p}&limit=${l}`);

//
export const deletecourse = (courseId) =>
  client.delete(endpointV1 + `?courseId=${courseId}`);

export const getRestrictedCourses = ({ course, page, limit, userid }) =>
  client.get(
    endpointV1 +
      `/search-restricted?course=${course}${
        userid ? `&userid=${userid}` : ""
      }&page=${page}&limit=${limit}`
  );

// v2 APIs
export const getcourseV2 = (title) =>
  client.get(endpointV2 + `?title=${title}`);

export const getvideolink = (courseId) =>
  client.get(endpointV2 + `/link?courseId=${courseId}`);

export const createcoursev2 = (details) =>
  client.post(endpointV2 + "/", details);

export const updateCourseV2 = (details) =>
  client.patch(endpointV2 + "/", details);

export const createtechnologiesv2 = (details) =>
  client.post(endpointV2 + "/technologies", details);

export const addcoursedetailsv2 = (details, id) => {
  return client.patch(endpointV2 + `/course-details?id=${id}`, details);
};

export const getcoursedetailsv2 = (id) => {
  return client.get(endpointV2 + `/creator-course?id=${id}`);
};

export const getprimarytechnologiesv2 = (key) => {
  return client.get(endpointV2 + `/technologies?search=${key}`);
};

export const getCategoriesv2 = (key) => {
  return client.get(endpointV2 + `/categories?key=${key}`);
};
export const addcoursepricev2 = (details) => {
  return client.patch(endpointV2 + `/price`, details);
};

export const addemailv2 = (details) => {
  return client.post(endpointV2 + `/email`, details);
};

export const updateEmailV2 = (details) => {
  return client.patch(endpointV2 + `/email`, details);
};

export const publishcoursev2 = (id) => {
  return client.patch(endpointV2 + `/publish?id=${id}`);
};

export const addReferralDiscount = (details, id) => {
  return client.patch(endpointV2 + `/referral?id=${id}`, details);
};

export const addOnCourse = (details, id) => {
  return client.patch(endpointV2 + `/add-on?id=${id}`, details);
};

export const getAddOnCourses = (id) => {
  return client.get(endpointV2 + `/add-on?id=${id}`);
};

export const getAddonAbleCourses = (query) => {
  return client.get(endpointV2 + `/add-on/courses?query=${query}`);
};

export const getAddOnCourseDetails = (cid) => {
  return client.get(endpointV2 + `/add-on/course?id=${cid}`);
};

export const updateAddOnCourse = (details, id) => {
  return client.patch(endpointV2 + `/add-on/update?id=${id}`, details);
};

export const deleteAddOnCourse = (courseId, addOnCourseId) => {
  return client.delete(
    endpointV2 + `/add-on?courseId=${courseId}&addOnCourseId=${addOnCourseId}`
  );
};
