import localForage from "localforage";

const VIEWS_CRITERIA = 3 / 100; //3%

export const createdb = (name) => {
  // let db = new Localbase(name);
  // db.config.debug = false;
  // return db;
  let db = localForage.createInstance({
    name,
    version: 3,
  });
  return db;
};

export const getbykey = async (dbname, key) => {
  // let db = new Localbase(dbname);
  let db = createdb(dbname);
  // db.config.debug = false;

  let result = await db.getItem(key);
  return result;
};

export const addbykey = async (dbname, data, key) => {
  // let db = new Localbase(dbname);
  // db.config.debug = false;
  let db = createdb(dbname);

  await db.setItem(key, data);
  // await db.collection(dbname).doc(key).set(data);
  let res = await getbykey(dbname, key);
  return res;
};

export const updatebykey = async (dbname, key, data, type = "login") => {
  // let db = new Localbase(dbname);
  let db = createdb(dbname);
  // db.config.debug = false;
  let result;
  if (type === "login") {
    result = await db.setItem(key, { login_state: data.login_state });
  }
  // else if (type === "streak") {
  //   result = await db.collection(dbname).doc(key).set({
  //     streakData: data.streakData,
  //     today: data.today,
  //     lastDate: data.lastDate,
  //   });
  // }
  else if (type === "life") {
    // result = await db.collection(dbname).doc(key).set({
    //   createdAt: data.createdAt,
    //   isNewDay: data.isNewDay,
    //   endOfDay: data.endOfDay,
    //   lifePoints: data.lifePoints,
    //   prevLifePoints: data.prevLifePoints,
    // });

    result = await db.setItem(key, {
      createdAt: data.createdAt,
      isNewDay: data.isNewDay,
      endOfDay: data.endOfDay,
      lifePoints: data.lifePoints,
      prevLifePoints: data.prevLifePoints,
    });
  } else if (type === "pref") {
    result = await db.setItem(key, {
      resolution: data.resolution,
      playrate: Number(data.playrate),
    });

    // result = await db
    //   .collection(dbname)
    //   .doc(key)
    //   .set({
    //     resolution: data.resolution,
    //     playrate: Number(data.playrate),
    //   });
  } else {
    result = await db.setItem(key, data);
  }
  return result;
};

export const deletebykey = async (dbname, key) => {
  // let db = new Localbase(dbname);
  // db.config.debug = false;
  let db = createdb(dbname);

  let res = await getbykey(dbname, key);
  if (res) await db.removeItem(key);

  // let res = await getbykey(dbname, key);
  // if (res) await db.collection(dbname).doc(key).delete();
};

export const deletebycollection = async (dbname) => {
  // let db = new Localbase(dbname);
  // db.config.debug = false;
  // await db.collection(dbname).delete();
  await localForage.dropInstance({
    name: dbname,
  });
};

export const addmetadata = async (dbname, data) => {
  let result = await getbykey(dbname, data.id);
  let res;
  if (result) {
    res = await addbykey(
      dbname,
      {
        ...result,
        initiate: data.initiate || Date.now(),
        currsecs: data.currsecs || 0,
        status: data.status || true,
      },
      data.id
    );
  } else {
    res = await addbykey(
      dbname,
      {
        id: data.id,
        lastplaystatus: data.lastplaystatus || 0,
        initiate: data.initiate || Date.now(),
        currsecs: data.currsecs || 0,
        status: data.status || true,
        views: false,
      },
      data.id
    );
  }
  return res;
};

export const getmetadata = async (dbname, id) => {
  let res = await getbykey(dbname, id);
  return res;
};

export async function onVideoSettingChange(playrate) {
  let currPrefVideo = await getbykey(process.env.INDEXDB_USER, "prefvideo");
  if (currPrefVideo === null) {
    await addbykey(
      process.env.INDEXDB_USER,
      { playrate: Number(playrate) },
      "prefvideo"
    );
  } else {
    await updatebykey(
      process.env.INDEXDB_USER,
      "prefvideo",
      { playrate: Number(playrate) },
      "pref"
    );
  }
  return;
}

export const updatemetadata = async (dbname, data, playrate, cb) => {
  // let db = new Localbase(dbname);
  // db.config.debug = false;
  let db = createdb(dbname);
  let timestamp = Date.now();
  // console.log({ playrate });
  //Pause all previous data with status === true.

  //=========
  await db.iterate((value, key, iterationNumber) => {
    if (value.status && key !== data.id) {
      value.status = false;
      db.setItem(key, value);
    }
  });

  // let prevmetadata = await db.collection(dbname).doc({ id: data.id }).get();
  let prevmetadata = await getbykey(dbname, data.id);
  // console.log("prevmetadata while update: ", prevmetadata, "data: ", data);
  let prevRate = await getbykey(process.env.INDEXDB_USER, "prefvideo");
  let datakey;

  let timeSpent = 0;
  let cpEarned = 0;
  if (prevmetadata && prevmetadata.status) {
    let isRateChange = prevRate.playrate != playrate;

    if (prevmetadata.status !== data.status || isRateChange) {
      cpEarned = Math.floor(Number(timestamp - prevmetadata.initiate) / 1000);
      timeSpent = Math.floor(
        (Number(timestamp - prevmetadata.initiate) / 1000) *
          (Number(prevRate.playrate) >= 1 ? Number(prevRate.playrate) : 1)
      );
    }

    datakey = {
      ...prevmetadata,
      initiate: timestamp,
      currsecs:
        prevmetadata.status !== data.status || isRateChange //?
          ? prevmetadata.currsecs + timeSpent
          : prevmetadata.currsecs, //previously it's 0 then data.lastplaystatus
      lastplaystatus: data.lastplaystatus,
      status: data.status,
    };

    cb(cpEarned);

    // await utils.shouldUpdateLife(timeSpent, data.life);
  } else {
    datakey = {
      ...prevmetadata,
      initiate: timestamp, //need to do some changes here. as when we are in false state and then change the resolution then still initiate get updated with current timestamp value.
      status: data.status,
      lastplaystatus: data.lastplaystatus,
    };
  }
  // console.log("datakey in updatemetadata: ", datakey);
  // await db.collection(dbname).doc({ id: data.id }).set(datakey);

  await db.setItem(data.id, datakey);
  await onVideoSettingChange(playrate);
  return datakey;
};

export const deletemetadata = async (dbname, id) => {
  // let db = new Localbase(dbname);
  // db.config.debug = false;
  // db.collection(dbname).doc({ id }).delete();
  await deletebykey(dbname, id);
};

export const isviewsCount = async (dbname, id, totallength) => {
  // let db = new Localbase(dbname);
  // db.config.debug = false;
  const metadata = await getmetadata(dbname, id);
  let seconds = parseFloat(
    Math.floor(totallength) * 60 + (totallength - Math.floor(totallength)) * 100
  );
  if (metadata.currsecs >= parseFloat(VIEWS_CRITERIA * seconds)) {
    // let data = await db.collection(dbname).doc({ id }).get();
    let data = await getbykey(dbname, id);
    data = {
      ...data,
      views: true,
    };
    // await db.collection(dbname).doc({ id }).set(data);
    await updatebykey(dbname, id, data, "metadata");
    // console.log("5% done views counted: ..");
    return true;
  }
  return false;
};
