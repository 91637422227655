import client from "./_client";

const endpointV1 = `/v1/views`;

export const addView = (videoId) => client.post(endpointV1, { videoId });

export const getVideoViews = ({ videoId, page, limit }) =>
  client.get(
    endpointV1 + `/video?videoId=${videoId}&page=${page}&limit=${limit}`
  );

export const getUserVideoViews = ({ userId, page, limit }) =>
  client.get(endpointV1 + `/user?userId=${userId}&page=${page}&limit=${limit}`);
