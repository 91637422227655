import { socketHandlePoints } from "@/socket";
import client from "./_client";
import utils from "@/utils";

const endpoint = "/v1/auth";
//GET REQUEST

export const logout = async (points, socket, userId) => {
  const response = await client.get(endpoint + "/logout");
  if (response?.data?.success > 400) {
    if (points > 0) {
      socketHandlePoints(
        {
          userId,
          points,
        },
        socket
      );
    }

    utils.setCookies("login_state", false);
    utils.deleteCookie("cs-firebase-token");
  }
  return response;
};

export const getme = () => client.get(endpoint);

export const checkusername = (username) =>
  client.get(endpoint + `/check-username?username=${username}`);

export const getmystats = () => client.get(endpoint + `/creator/stats`);

export const sendverifyemail = () => client.get(endpoint + `/verify`);

//PATCH REQUEST

export const updateuser = (details) => client.patch(endpoint, details);

export const updatepersonaldetails = (details) =>
  client.patch(endpoint + "/personal", details);

export const updatepassword = (details) =>
  client.patch(endpoint + `/password`, details);

export const updateusername = (username) =>
  client.patch(endpoint + `/update-username`, { username });

export const updateProfileCpStatus = (fieldName) =>
  client.patch(endpoint + `/cpstate`, { fieldName });

//apis to get followers and followings
export const getAuthUserFollowing = (userId, key) =>
  client.get(endpoint + `/getFollowing?id=${userId}&key=${key}`);

export const getAuthUserFollowers = (userId, key) =>
  client.get(endpoint + `/getFollowers?id=${userId}&key=${key}`);

export const validateTaggedUsers = (taggedUsers) =>
  client.post(endpoint + "/verify-tags", { taggedUsers });

// creating the profile details
export const createProject = async (details) =>
  client.put(endpoint + "/projects", details);

export const createEducation = async (details) =>
  client.put(endpoint + "/education", details);

export const createExperience = async (details) =>
  client.put(endpoint + "/experience", details);

export const createCertificate = async (details) =>
  client.put(endpoint + "/certificates", details);

export const createAward = async (details) =>
  client.put(endpoint + "/awards", details);

// updating the profile
export const updateSkills = async (details) =>
  client.patch(endpoint + "/skills", details);

export const updateCertificates = async (details) =>
  client.patch(endpoint + "/certificates", details);

export const updateSocials = async (details) =>
  client.patch(endpoint + "/socials", details);

export const updateContact = async (details) =>
  client.patch(endpoint + "/contact", details);

export const updateProject = async (details) =>
  client.patch(endpoint + "/projects", details);

export const updateExperience = async (details) =>
  client.patch(endpoint + "/experience", details);

export const updateEducation = async (details) =>
  client.patch(endpoint + "/education", details);

export const updateAwards = async (details) =>
  client.patch(endpoint + "/awards", details);

export const updateAbout = async (details) =>
  client.patch(endpoint + "/aboutdetails", details);

export const getUserDetails = async () => client.get(endpoint + "/userdetails");

export const getProfileDetails = async (id) =>
  client.get(endpoint + `/profiledetails?id=${id}`);

// deleting endpoints
export const deleteProject = async (details) =>
  client.patch(endpoint + "/projects/remove", details);

export const deleteEducation = async (details) =>
  client.patch(endpoint + "/education/remove", details);

export const deleteExperience = async (details) =>
  client.patch(endpoint + "/experience/remove", details);

export const deleteCertificate = async (details) =>
  client.patch(endpoint + "/certificates/remove", details);

export const deleteAward = async (details) =>
  client.patch(endpoint + "/awards/remove", details);
