import {
  getMessaging,
  getToken,
  isSupported as isMessagingSupported,
  onMessage,
} from "firebase/messaging";
import {
  getAnalytics,
  isSupported as isAnalyticsSupported,
} from "firebase/analytics";
import { initializeApp } from "firebase/app";

import { setCookies } from "./cookie";
import apis from "@/api";

const firebaseConfig = {
  apiKey: "AIzaSyDjEizV_4zNsI3lhPhDLFZBUMbkfsZJKIg",
  authDomain: "cipherschools-app.firebaseapp.com",
  projectId: "cipherschools-app",
  storageBucket: "cipherschools-app.appspot.com",
  messagingSenderId: "201265331905",
  appId: "1:201265331905:web:9203d1d10c7ecc0558de40",
  measurementId: "G-GB5W3TVE2G",
};

// Initialize Firebase Cloud Messaging and get a reference to the service
const app = initializeApp(firebaseConfig);

export function requestPermission({ userId, email, isIos }) {
  // Check if notifications are supported
  if (typeof Notification === "undefined") {
    console.error("Notification not supported");
    return;
  }

  let analytics;
  if (typeof window !== "undefined") {
    isAnalyticsSupported().then((supported) => {
      if (supported) {
        analytics = getAnalytics(app);
      } else {
        console.warn(
          "Firebase Analytics is not supported in this environment."
        );
      }
    });
  }

  let messaging;
  if (typeof window !== "undefined") {
    isMessagingSupported().then((supported) => {
      if (supported) {
        messaging = getMessaging();
      } else {
        console.warn(
          "Firebase Messaging is not supported in this environment."
        );
      }
    });
  }

  Notification?.requestPermission()
    .then((permission) => {
      if (permission === "granted") {
        getToken(messaging, {
          vapidKey:
            "BKykCpxwGbcI_EPqoi16UnMpnsIm4KhKGOjrpFx7CmRgkT9UoFK7xQ6n8Svca_CCnruiDWKGBxVwolK9fOTrD4M",
        })
          .then((currentToken) => {
            if (currentToken) {
              // Send the token to your server and update the UI if necessary
              // need to change json token and instead of only userId add email and name
              try {
                const data = {
                  userId,
                  email,
                  tokens: [{ token: currentToken, isAvailable: true, isIos }],
                };
                //...api call for subscription

                apis
                  .createsubscription(data)
                  .then((res) => {
                    if (res.data?.success === 200)
                      setCookies("cs-firebase-token", currentToken, 28);
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              } catch (error) {
                console.error("error in subscription: ", error);
              }
            } else {
              // Show permission request UI
              console.error(
                "No registration token available. Request permission to generate one."
              );
            }
          })
          .catch((err) => {
            console.error("An error occurred while retrieving token. ", err);
            // ...
          });
      } else {
        console.error(" Notification Permission not granted.");
      }
    })
    .catch((error) => console.error("error in fetching token: ", error));
}

export const onMessageListener = () => {
  // Initialize Firebase
  // Initialize Firebase Cloud Messaging and get a reference to the service
  const analytics = getAnalytics(app);
  const messaging = getMessaging(app);

  return new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
};
