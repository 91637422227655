import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

export function getTime(date) {
  return dayjs(date).fromNow();
}

export function timestampDifference(timestamp) {
  const currTimestamp = new Date();
  const diff = Math.abs(currTimestamp - new Date(timestamp)) / 1000; // Difference in seconds
  return diff;
}

export function formatSeconds(
  timeInSeconds,
  hour = true,
  min = true,
  sec = true
) {
  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor((timeInSeconds % 3600) / 60);
  const seconds = Math.floor(timeInSeconds % 60);
  return (
    <>
      {hour && (
        <>
          <span style={{ color: "var(--brand-color)" }}>
            {hours > 9 ? hours : "0" + hours}
          </span>{" "}
          Hour
          {hours > 9 ? "s" : ""}
        </>
      )}
      {min && (
        <>
          {" "}
          :{" "}
          <span style={{ color: "var(--brand-color)" }}>
            {minutes > 9 ? minutes : "0" + minutes}
          </span>{" "}
          Minute
          {minutes > 9 ? "s" : ""}
        </>
      )}
      {sec && (
        <>
          {" "}
          :{" "}
          <span style={{ color: "var(--brand-color)" }}>
            {seconds > 9 ? seconds : "0" + seconds}
          </span>{" "}
          Second
          {seconds > 9 ? "s" : ""}
        </>
      )}
    </>
  );
}

export function convertTime(decimalTime) {
  const totalMinutes = Math.floor(decimalTime);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  const seconds = Math.round((decimalTime - Math.floor(decimalTime)) * 60);

  if (hours > 0) {
    if (minutes > 0) {
      return `${hours} hr ${minutes} min`;
    } else {
      return `${hours} hr`;
    }
  } else if (minutes > 0) {
    if (seconds > 0) {
      return `${minutes} min ${seconds} sec`;
    } else {
      return `${minutes} min`;
    }
  } else {
    return `${seconds} sec`;
  }
}

export function secondsToHMS(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

export function millisecondsToTarget(isoString, targetHours) {
  const date = new Date(isoString);
  const now = Date.now();
  const targetTime = date.getTime() + targetHours * 60 * 60 * 1000;
  const millisecondsLeft = targetTime - now;

  return millisecondsLeft;
}

export function formatISODate(isoString) {
  const date = new Date(isoString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}
