import client from "./_client";

const endpointV1 = `/v1/enrollments`;

export const fetchRestrictedCourse = async () =>
  client.get(endpointV1 + `/restrictedcourse`);

export const myEnrollments = async () => client.get(endpointV1);

export const fetchEnrollments = async ({ uid, status, page, limit }) =>
  client.get(
    endpointV1 +
      `/courses?uid=${uid}&status=${status}&page=${page}&limit=${limit}`
  );

export const courseEnroll = (courseId) => client.post(endpointV1, { courseId });

export const courseUnenroll = (id) => client.delete(endpointV1 + `?id=${id}`);

export const updateProgress = (courseId, video) =>
  client.patch(endpointV1 + `/updateprogress?id=${courseId}`, {
    videoId: video,
  });

export const getTestEligibility = ({ courseId, stageId }) => {
  return client.get(
    endpointV1 + `/test?stageId=${stageId}&courseId=${courseId}`
  );
};

export const enrollStudentToTest = (body) => {
  return client.put(endpointV1 + `/enroll-to-test`, body);
};
