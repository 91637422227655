import utils from "@/utils";
import client from "./_client";

const endpointV1 = `/v1/videos`;
const endpointV2 = `/v2/videos`;

export const getvideo = (id) => client.get(endpointV1 + `?id=${id}`);

export const checkUniqueVideoTitle = (title) =>
  client.get(endpointV1 + `/check-unique-title?title=${title}`);

export const isVideoRestricted = (title) =>
  client.get(endpointV1 + `/is-restricted?title=${title}`);

export const getsrc = (id) => client.get(endpointV1 + `/signed?id=${id}`);

export const getcoursevideos = ({ courseId, stage, page, limit = 10 }) =>
  client.get(
    endpointV1 +
      `/coursesvideo?courseId=${courseId}&stage=${stage}&page=${page}&limit=${limit}`
  );

export const getsuggestionvideos = (id, p = 1, l = 10) =>
  client.get(endpointV1 + `/suggestionvideo?id=${id}&page=${p}&limit=${l}`);

export const videosbycreator = ({ id, username, page = 1, limit = 4 }) =>
  client.get(
    endpointV1 +
      `/creator?${id ? `id=${id}` : `username=${username}`}&page=${page}&limit=${limit}`
  );

//UPLOAD
export const profileUpload = async (file) => {
  try {
    //Secure url
    const result = await client.get(endpointV1 + "/geturl");

    //post the video directly to the s3 bucket
    const res = await fetch(result.data?.url, {
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: file[0],
    });

    let url = utils.generateCloudUrl("assets", result.data?.fileName);

    //post request some extra things to our backend
    const response = await client.patch("/v1/auth/profile", {
      profileImage: url,
    });

    return response;
  } catch (error) {
    throw new Error(error);
  }
};
// UPLOAD MEDIA FILES
export const mediaUpload = async (file) => {
  try {
    //Secure url
    const result = await client.get(endpointV1 + "/geturl");

    //post the video directly to the s3 bucket
    const res = await fetch(result.data?.url, {
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: file,
    });

    // TODO: Ask Nitesh if we should keep it in assets in S3 bucket!
    let url = utils.generateCloudUrl("assets", result.data.fileName);

    return url;
  } catch (error) {
    throw new Error(error);
  }
};

export const thumbnailUpload = async (file, type = "") => {
  try {
    //SECURE URL
    let result;
    if (type === "courseThumbnails") {
      result = await client.get(endpointV1 + "/geturl?type=thumbnail");
    } else {
      result = await client.get(endpointV1 + "/geturl");
    }

    // return;
    //post the video directly to the s3 bucket
    const res = await fetch(result.data.url, {
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: file[0],
    });

    let url = utils.generateCloudUrl(
      type === "courseThumbnails" ? "courseThumbnails" : "assets",
      result.data.fileName
    );

    return url;
  } catch (error) {
    throw new Error(error);
  }
};

export const uploadPromoVideo = async (file) => {
  try {
    //SECURE URL
    const result = await client.get(endpointV1 + `/geturl?type=promotionVideo`);

    //post the video directly to the s3 bucket
    const res = await fetch(result.data.url, {
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: file[0],
    });

    let url = utils.generateCloudUrl("promotionVideo", result.data.fileName);
    return url;
  } catch (error) {
    throw new Error(error);
  }
};

export const uploadCurriculum = async (file) => {
  try {
    //SECURE URL
    const result = await client.get(endpointV1 + `/geturl?type=curriculum`);

    //post the video directly to the s3 bucket
    const res = await fetch(result.data.url, {
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: file[0],
    });

    let url = utils.generateCloudUrl("curriculum", result.data.fileName);
    return url;
  } catch (error) {
    throw new Error(error);
  }
};

export const uploadTechnologyImage = async (file) => {
  try {
    //SECURE URL
    const result = await client.get(endpointV1 + `/geturl?type=technologies`);

    //post the video directly to the s3 bucket
    const res = await fetch(result.data?.url, {
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: file[0],
    });

    let url = utils.generateCloudUrl("technologies", result.data.fileName);
    return url;
  } catch (error) {
    throw new Error(error);
  }
};

export const getKeys = async () => client.get(endpointV1 + `/keys`);

export const getrecommendedvideos = (p = 1, l = 10) =>
  client.get(endpointV1 + `/recommended?page=${p}&limit=${l}`);

export const savevideo = (details) => client.post(endpointV1, details);

export const updatevideo = (videoId, details) =>
  client.patch(endpointV1 + `?id=${videoId}`, details);

export const deletevideo = (videoId) =>
  client.delete(endpointV1 + `?videoId=${videoId}`);

// ------------------ V2 API's ------------------
export const getVideoV2 = (videoTitle, type) =>
  client.get(endpointV2 + `?videoTitle=${videoTitle}&type=${type}`);

export const getsuggestionvideosV2 = (title, p = 1, l = 10) =>
  client.get(
    endpointV2 + `/suggestionvideo?title=${title}&page=${p}&limit=${l}`
  );

export const addVideoLectureV2 = (details, stageId) =>
  client.post(endpointV2 + `/?id=${stageId}`, details);

export const updateVideoLectureV2 = (details, videoId) =>
  client.patch(endpointV2 + `/?id=${videoId}`, details);
