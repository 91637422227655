import client from "./_client";

const endpointV1 = `/v1/feedbacks`;

export const postFeedback = (reportBody) => client.post(endpointV1, reportBody);

export const getUserFeedbacks = ({ page, limit = 8 }) =>
  client.get(endpointV1 + `/user?page=${page}&limit=${limit}`);

export const isUserFeedbackExist = (courseId, type) =>
  client.get(endpointV1 + `?courseId=${courseId}&type=${type}`);
