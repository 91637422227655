export function formatDate(inputDate) {
  try {
    const day = inputDate.getDate();
    const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
      inputDate
    );
    const year = inputDate.getFullYear().toString().slice(-2);
    const formattedDate = `${day} ${month} ${year}`;
    return formattedDate;
  } catch (error) {
    return "";
  }
}

export function formatFullDate(inputDate) {
  const day = inputDate.getDate();
  const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
    inputDate
  );
  const year = inputDate.getFullYear().toString();
  const formattedDate = `${month} ${day}, ${year}`;
  return formattedDate;
}

export function formatMMYYYYFromDate(isoDateString) {
  const dateObject = new Date(isoDateString);
  if (isoDateString === "present") return "Present";
  if (isNaN(dateObject.getTime())) {
    // Handle invalid date format
    return null;
  }

  const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, "0");
  const year = dateObject.getUTCFullYear();

  return `${month}/${year}`;
}

export const timeDifference = (value, value2 = new Date(), suffix = "ago") => {
  if (!value) return "";
  const date = new Date(value);
  const now = new Date(value2);
  let diff = 0;
  if (date > now) diff = date.getTime() - now.getTime();
  else diff = now.getTime() - date.getTime();

  const seconds = Math.ceil(diff / 1000);
  const minutes = Math.ceil(seconds / 60);
  const hours = Math.ceil(minutes / 60);
  const days = Math.ceil(hours / 24);

  if (seconds < 60) return `${seconds} sec${seconds > 1 ? "s" : ""} ${suffix}`;
  if (minutes < 60) return `${minutes} min${minutes > 1 ? "s" : ""} ${suffix}`;
  if (hours < 24) return `${hours} hr${hours > 1 ? "s" : ""} ${suffix}`;
  if (days < 7) return `${days} day${days > 1 ? "s" : ""} ${suffix}`;

  return formatDate(date);
};
