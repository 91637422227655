function linkify(text, verificationArray = []) {
  // Replace links, hashtags, and mentions with anchor tags
  const linkedText = text?.replace(
    /(https?:\/\/[^\s]+)|#(\w+)|@(\w+)/g,
    (match, link, tag, username) => {
      if (link) {
        return `<a href="${link}" target='_blank'>${link}</a>`;
      } else if (tag) {
        return `<a href="${process.env.PROD_JOIN_BASEURL}/search?query=${encodeURIComponent(
          tag
        )}"> ${match}</a>`;
      } else if (username) {
        if (verificationArray?.map((user) => user?._id).includes(username)) {
          const _username = verificationArray
            .filter((user) => user?._id === username)
            .map((user) => user.username);
          return `<a href="${process.env.PROD_JOIN_BASEURL}/profile/${_username}">@${_username}</a>`;
        }
      }
      return match;
    }
  );

  return linkedText;
}

export default linkify;
