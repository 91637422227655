"use client";

const { cookies } = require("next/headers");

// Client-side: Set a cookie
export const setCookies = (cname, cvalue, exdays, options = {}) => {
  let d = new Date();

  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();

  const { secure = false, sameSite = "Lax" } = options;
  const secureFlag = secure ? "; Secure" : "";
  const sameSiteFlag = sameSite ? `; SameSite=${sameSite}` : "";

  document.cookie =
    `${cname}=${encodeURIComponent(cvalue)}; ${expires}; path=/` +
    secureFlag +
    sameSiteFlag;
};

// Server-side: Set a cookie using next/headers
export const setServerSideCookie = (cname, cvalue, exdays, options = {}) => {
  const headers = cookies();

  const expires = new Date();
  expires.setTime(expires.getTime() + exdays * 24 * 60 * 60 * 1000);

  const { secure = false, sameSite = "Lax" } = options;

  headers.set(cname, encodeURIComponent(cvalue), {
    path: "/",
    expires,
    secure,
    sameSite,
  });
};

// Client-side: Get a cookie
export const getCookie = (cname) => {
  if (typeof document === "undefined") return null;

  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      const cookieValue = c.substring(name.length, c.length);
      try {
        return JSON.parse(cookieValue); // Try to parse as JSON
      } catch (error) {
        return cookieValue; // Return raw value if parsing fails
      }
    }
  }
  return "";
};

// Server-side: Get a cookie using next/headers
export const getServerSideCookie = (cname) => {
  const headers = cookies();
  const cookieValue = headers.get(cname)?.value || "";
  try {
    return JSON.parse(decodeURIComponent(cookieValue)); // Try to parse as JSON
  } catch (error) {
    return decodeURIComponent(cookieValue); // Return raw value if parsing fails
  }
};

// Client-side: Delete a cookie
export const deleteCookie = (cname) => {
  document.cookie = `${cname}=; expires=${new Date(0).toUTCString()}; path=/`;
};

// Server-side: Delete a cookie using next/headers
export const deleteServerSideCookie = (cname) => {
  const headers = cookies();
  headers.set(cname, "", {
    path: "/",
    expires: new Date(0), // Expired date to delete the cookie
  });
};
