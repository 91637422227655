import client from "./_client";

const endpointV1 = `/v1/notes`;

export const addNote = (note) => client.post(endpointV1, note);

export const getNote = (noteId) => client.get(endpointV1 + `?noteId=${noteId}`);

export const getVideoNotes = (videoId, sort, p = 1, l = 10) =>
  client.get(
    endpointV1 + `/videos?videoId=${videoId}&sort=${sort}&page=${p}&limit=${l}`
  );

export const getCourseNotes = (courseId) =>
  client.get(endpointV1 + `/courses?courseId=${courseId}`);

export const videoTitles = (courseId) =>
  client.get(endpointV1 + `/entity?courseId=${courseId}`);

export const allNotesByCourse = (courseId, sort, p = 1, l = 10) =>
  client.get(
    endpointV1 +
      `/course?courseId=${courseId}&sort=${sort}&page=${p}&limit=${l}`
  );

export const getNotesPdf = (courseId) =>
  client.post(
    endpointV1 + `/pdf`,
    { courseId },
    {
      responseType: "blob",
    }
  );

export const updateNote = (noteId, details) =>
  client.patch(endpointV1 + `?noteId=${noteId}`, details);

export const deleteNote = (noteId) =>
  client.delete(endpointV1 + `?noteId=${noteId}`);
