import client from "./_client";

const endpointV1 = `/v1/points`;

export const getWatchPoint = (userId = null) =>
  client.get(endpointV1 + `?userId=${userId}`);

export const getUserActivity = () =>
  client.get(endpointV1 + "/getUserActivity");

export const getMissions = () => client.get(endpointV1 + "/missions");
