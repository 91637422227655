import client from "./_client";

const endpointV1 = `/v1/requests`;

export const postRequest = (requestBody) =>
  client.post(endpointV1, requestBody);

export const getUserRequests = ({ page, limit = 8 }) =>
  client.get(endpointV1 + `/user?page=${page}&limit=${limit}`);

export const isUserRequestExist = (userId, type) =>
  client.get(endpointV1 + `?userId=${userId}&type=${type}`);

export const deleteUser = (details) => client.post(endpointV1, details);
