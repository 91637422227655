import {
  isLogin,
  isCreator,
  isAdmin,
  generateCloudUrl,
  restoreLifeData,
  shouldUpdateLife,
  getCurrVidSerial,
  calculateprogress,
  // streamingVideo,
  calculateWatchPoint,
  calculateCipherPoints,
  // calculateStreak,
  changem3u8Tomp4,
  getThumbnailFromSrc,
  difftimestamp,
  difftimestampindays,
  getTheme,
  isvalidUrl,
  formatDateValue,
  getTemplateById,
  getIdByTemplate,
} from "./helper";
import {
  createdb,
  addbykey,
  getbykey,
  updatebykey,
  deletebykey,
  deletebycollection,
  addmetadata,
  getmetadata,
  onVideoSettingChange,
  updatemetadata,
  deletemetadata,
  isviewsCount,
} from "./indexdb";
import {
  addToLocalStorage,
  getFromLocalStorage,
  removeFromLocalStorage,
} from "./localstorage";
import {
  addToSessionStorage,
  getFromSessionStorage,
  removeFromSessionStorage,
} from "./sessionstorage";
import {
  getTime,
  secondsToHMS,
  formatSeconds,
  timestampDifference,
  convertTime,
  millisecondsToTarget,
  formatISODate,
} from "./getTime";
import {
  setCookies,
  setServerSideCookie,
  getCookie,
  deleteCookie,
} from "./cookie";
import formatNumber from "./formatNumber";
import linkify from "./linkify";

import {
  timeDifference,
  formatDate,
  formatMMYYYYFromDate,
  formatFullDate,
} from "./formatDate";
import getRandomColor from "./getRandomColor";

import { onMessageListener, requestPermission } from "./firebaseInit";

const utils = {
  //Helper
  isLogin,
  isCreator,
  isAdmin,
  generateCloudUrl,
  restoreLifeData,
  getCurrVidSerial,
  calculateprogress,
  shouldUpdateLife,
  calculateWatchPoint,
  calculateCipherPoints,
  changem3u8Tomp4,
  getThumbnailFromSrc,
  difftimestamp,
  difftimestampindays,
  getTheme,
  isvalidUrl,
  formatDateValue,
  getTemplateById,
  getIdByTemplate,
  //IndexDB
  createdb,
  addbykey,
  getbykey,
  updatebykey,
  deletebykey,
  deletebycollection,
  addmetadata,
  getmetadata,
  onVideoSettingChange,
  updatemetadata,
  deletemetadata,
  isviewsCount,
  //Localstorage
  addToLocalStorage,
  getFromLocalStorage,
  removeFromLocalStorage,
  //SessionStorage
  addToSessionStorage,
  getFromSessionStorage,
  removeFromSessionStorage,
  //Cookie
  setCookies,
  setServerSideCookie,
  getCookie,
  deleteCookie,
  //getTime
  getTime,
  secondsToHMS,
  formatSeconds,
  timestampDifference,
  convertTime,
  millisecondsToTarget,
  formatISODate,
  // formatNumber
  formatNumber,
  //linkify
  linkify,
  //formatDate
  formatDate,
  formatFullDate,
  timeDifference,
  formatMMYYYYFromDate,
  //random-color
  getRandomColor,
  //firebase
  onMessageListener,
  requestPermission,
};

export default utils;
