import client from "./_client";

const endpointV1 = `/v1/comments`;

export const getComments = (id, p = 1, l = 20, commentId = "") =>
  client.get(
    endpointV1 + `?id=${id}&page=${p}&limit=${l}&commentId=${commentId}`
  );

export const saveComment = async (details, clearComment, cb) => {
  const response = await client.post(endpointV1, details);
  if (response.data?.success === 200) {
    clearComment();
    cb(response?.data?.result?._id);
  }
  return response.ok;
};

export const likeComment = (id) => client.patch(endpointV1 + `/like?id=${id}`);

export const unlikeComment = (id) =>
  client.patch(endpointV1 + `/unlike?id=${id}`);

export const deleteComment = (id) => client.delete(endpointV1 + `?id=${id}`);
