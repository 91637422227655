import client from "./_client";

const endpoint = "/v1/batches";

export const getAllBatches = async (page, limit) =>
  client.get(endpoint + `/user?page=${page}&limit=${limit}`);

export const getBatch = async ({ id }) =>
  client.get(endpoint + `?batchId=${id}`);

export const getBatchResource = async ({ batchId, type, page, limit }) =>
  client.get(
    endpoint +
      `/get?batchId=${batchId}&type=${type}&page=${page}&limit=${limit}`
  );

export const getMySubmission = async (id) =>
  client.get(endpoint + `/submission?id=${id}`);

export const getMyContents = async (id) =>
  client.get(endpoint + `/bundle-content?id=${id}`);

export const markMentorAttendence = async ({ batchId }) =>
  client.patch(endpoint + `/attendence?batchId=${batchId}`);

export const increaseResourceClickCount = async ({ resourceId }) =>
  client.patch(endpoint + `/clicks?id=${resourceId}`);

export const addResource = async (body) =>
  client.post(endpoint + `/resource`, body);

export const addBatchAssignment = async (body) =>
  client.post(endpoint + `/assignment`, body);

export const addBatchSubmission = async (body) =>
  client.post(endpoint + `/submission`, body);

export const updateResource = async (body) =>
  client.patch(endpoint + `/resource`, body);

export const updateBatchAssignment = async (body) =>
  client.patch(endpoint + `/assignment`, body);

export const updateBatchSubmission = async (body) =>
  client.patch(endpoint + `/submission`, body);

export const deleteResource = async (resourceId) =>
  client.delete(endpoint + `/resource?id=${resourceId}`);

export const deleteBatchAssignment = async (assignmentId) =>
  client.delete(endpoint + `/assignment?id=${assignmentId}`);
