import client from "./_client";

const endpointV1 = `/v1/notifications`;
const endpointV2 = `/v2/notifications`;

export const getNotifications = (page = 1, limit = 20) =>
  client.get(endpointV2 + `?page=${page}&limit=${limit}`);

export const getUnreadNotifications = () => client.get(endpointV1 + `/unread`);

export const getMentions = (page = 1, limit = 20) =>
  client.get(endpointV1 + `/mention?page=${page}&limit=${limit}`);

export const createNotification = (data) => client.post(endpointV1 + "/", data);

export const getCommentMentionVideo = (commentId) =>
  client.get(endpointV1 + `/getCourseId?commentId=${commentId}`);
