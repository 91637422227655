import client from "./_client";

const endpointV1 = "/v1/assignments";

export const createassignment = (details) => client.post(endpointV1, details);

export const updateassignment = (id, details) =>
  client.patch(endpointV1 + `?id=${id}`, details);

export const getassignment = (id) => client.get(endpointV1 + `/video?id=${id}`);

export const getassignmentsbyme = (page = 1, limit = 8) =>
  client.get(endpointV1 + `/me?page=${page}&limit=${limit}`);

export const getassignmentcount = () => client.get(endpointV1, "/count");

export const deleteassignment = (aid) =>
  client.delete(endpointV1, `?id=${aid}`);
