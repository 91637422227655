import client from "./_client";

const endpointV1 = `/v1/cohorts`;

export const createCohort = (details) => client.post(endpointV1 + "/", details);

export const myCohorts = (filter = "all", p = 1, l = 4) =>
  client.get(endpointV1 + `?filter=${filter}&page=${p}&limit=${l}`);

export const updateCohort = (details) =>
  client.patch(endpointV1 + "/", details);

export const addCohortdetails = (details, id) => {
  return client.patch(endpointV1 + `/cohort-details?id=${id}`, details);
};

export const getCohortdetails = (id) => {
  return client.get(endpointV1 + `/creator-cohort?id=${id}`);
};

export const getCohortCurriculum = (id) => {
  return client.get(endpointV1 + `/curriculum?id=${id}`);
};

export const updateCohortCurriculum = (data) => {
  return client.patch(endpointV1 + `/curriculum`, data);
};

export const addCohortEmail = (details) => {
  return client.post(endpointV1 + `/email`, details);
};

export const updateCohortEmail = (details) => {
  return client.patch(endpointV1 + `/email`, details);
};

export const publishCohort = (id) => {
  return client.patch(endpointV1 + `/publish?id=${id}`);
};

export const deleteCohort = (id) => client.delete(endpointV1 + `?id=${id}`);
