import client from "./_client";

const endpointV1 = `/v1/wishlist`;

export const addWatchLaterVideo = (videoId, courseId) =>
  client.post(endpointV1, { videoId, courseId });

export const getAllWatchLaterVideos = (filter, page, limit = 8) =>
  client.get(endpointV1 + `?filter=${filter}&page=${page}&limit=${limit}`);

export const removeWatchLaterVideo = (videoId) =>
  client.delete(endpointV1 + `?videoId=${videoId}`);
