import client from "./_client";

const endpointV1 = "/v1/resumes";

export const getResume = (resumeId) =>
  client.get(endpointV1 + `?resumeId=${resumeId}`);

export const createResume = (details) => client.post(endpointV1 + `/`, details);

export const saveResume = (details) =>
  client.patch(endpointV1 + `/save`, details);

export const updateResume = (details, resumeId) =>
  client.patch(endpointV1 + `?resumeId=${resumeId}`, details, {
    responseType: "blob",
  });

export const updateTemplate = (templateId, resumeId) =>
  client.patch(endpointV1 + `?resumeId=${resumeId}`, { templateId });

export const fetchResumesData = (userId) =>
  client.get(endpointV1 + `/user?userId=${userId}`);

export const resetResumeData = (details) =>
  client.patch(endpointV1 + `/reset`, details, {
    responseType: "blob",
  });

export const getFirstPdf = (resumeId, templateId) =>
  client.post(
    endpointV1 + `/firstview`,
    { resumeId, templateId },
    {
      responseType: "blob",
    }
  );

export const deleteResumeData = (resumeId) =>
  client.delete(endpointV1 + `?resumeId=${resumeId}`);
