// import StreakMenu from "data/StreakMenu";
import utils from ".";

const LIFE_UPDATE_CRITERIA = 4 * 60; //4 minutes

export const isLogin = (user) => {
  if (utils.getCookie("login_state")) {
    return true;
  }
  if (user) {
    let isExist = user?.email ? true : false;
    return isExist;
  } else {
    utils.deleteCookie("creator_state");
    utils.setCookies("login_state", false);
    utils.setCookies("verification", false);
    return false;
  }
};

export const isCreator = (user) => {
  if (utils.getCookie("creator_state")) {
    return true;
  }
  if (user) {
    let isExist = user.isCreator ? true : false;
    return isExist;
  } else {
    utils.deleteCookie("creator_state");
    utils.setCookies("login_state", false);
    utils.setCookies("verification", false);
    return false;
  }
};

export const isAdmin = (user) => {
  if (utils.getCookie("admin_state")) {
    return true;
  }
  if (user) {
    let isExist = user.isAdmin ? true : false;
    return isExist;
  } else {
    return false;
  }
};

//CLOUDFRONT URL

export const generateCloudUrl = (type, response, i) => {
  let url;

  if (type === "video") {
    url = `${process.env.NEXT_PUBLIC_CLOUDFRONT_VIDEO_URL}/${response}/MP4/${response}.mp4`;
  } else if (type === "hls") {
    url = `${process.env.NEXT_PUBLIC_CLOUDFRONT_VIDEO_URL}/${response}/HLS/${response}.m3u8`;
  } else if (type === "thumbnail") {
    url = `${process.env.NEXT_PUBLIC_CLOUDFRONT_VIDEO_URL}/${response}/Thumbnails/${response}.000000${i}.jpg`;
  } else if (type === "assets") {
    url = `${process.env.NEXT_PUBLIC_CLOUDFRONT_ASSETS_URL}/${response}`;
  } else if (type === "courseThumbnails") {
    url = `${process.env.NEXT_PUBLIC_CLOUDFRONT_ASSETS_URL}/thumbnails/${response}`;
  } else if (type === "promotionVideo") {
    url = `${process.env.NEXT_PUBLIC_CLOUDFRONT_ASSETS_URL}/promotionVideos/${response}`;
  } else if (type === "curriculum") {
    url = `${process.env.NEXT_PUBLIC_CLOUDFRONT_ASSETS_URL}/curriculums/${response}`;
    // url = `${process.env.CLOUDFRONT_ASSETS_URL}/${response}`;
  } else if (type === "technologies") {
    url = `${process.env.NEXT_PUBLIC_CLOUDFRONT_ASSETS_URL}/technologies/${response}`;
    // url = `${process.env.CLOUDFRONT_ASSETS_URL}/${response}`;
  }
  return url;
};

//Data feeding
export const restoreLifeData = async (prevLife) => {
  let currLife = await utils.getbykey(
    process.env.INDEXDB_SERVICEWORKER,
    "service-life"
  );
  // console.log("restore life data prevLife, currLife: ", prevLife, currLife);
  if (
    !currLife ||
    currLife.endOfDay < prevLife.endOfDay ||
    currLife.createdAt < prevLife.createdAt ||
    (currLife.createdAt === prevLife.createdAt &&
      currLife.endOfDay === prevLife.endOfDay &&
      currLife.lifePoints < prevLife.lifePoints)
  ) {
    prevLife["prevLifePoints"] = prevLife.lifePoints;
    prevLife["isNewDay"] = false;
    await utils.addbykey(
      process.env.INDEXDB_SERVICEWORKER,
      prevLife,
      "service-life"
    );
  }
};

export const shouldUpdateLife = async (timeSpent, prevLife) => {
  let currLife = await utils.getbykey(
    process.env.INDEXDB_SERVICEWORKER,
    "service-life"
  );
  //restore if one deletes cache
  if (
    currLife === null ||
    currLife.endOfDay < prevLife.endOfDay ||
    currLife.createdAt < prevLife.createdAt ||
    (currLife.createdAt === prevLife.createdAt &&
      currLife.endOfDay === prevLife.endOfDay &&
      currLife.lifePoints < prevLife.lifePoints)
  ) {
    await restoreLifeData(prevLife);
    currLife = await utils.getbykey(
      process.env.INDEXDB_SERVICEWORKER,
      "service-life"
    );
  }

  // console.log(
  //   "currLife, timespent and isSameday in shouldupdateLife: ",
  //   currLife,
  //   timeSpent,
  //   difftimestamp(currLife.createdAt)
  // );

  if (currLife) {
    let updatedLife;

    if (difftimestamp(currLife.createdAt)) {
      updatedLife = {
        ...currLife,
        isNewDay: false,
        lifePoints: Number(currLife.lifePoints + timeSpent),
      };
    } else {
      updatedLife = {
        prevLifePoints: 0,
        isNewDay: true,
        createdAt: Date.now(),
        lifePoints: Number(timeSpent),
        endOfDay: currLife.endOfDay, //we are not updating endOfDay in cache, it changes with database.
      };
    }

    // console.log("updatedLife in shouldUpdatelife: ", updatedLife);

    if (
      Math.abs(updatedLife.lifePoints - updatedLife.prevLifePoints) >=
        LIFE_UPDATE_CRITERIA ||
      updatedLife.isNewDay
    ) {
      let life = {
        ...updatedLife,
        prevLifePoints: updatedLife.lifePoints,
        endOfDay: Number(
          updatedLife.endOfDay +
            (updatedLife.lifePoints - updatedLife.prevLifePoints)
        ),
      };
      if (life.endOfDay !== null) {
        // console.log("updateWatchPoint api called", life);
        // await api.updatewatchpoint(life);
        life["isNewDay"] = false;
      }
      updatedLife = Object.assign(life);
    }
    if (updatedLife.endOfDay !== null)
      // console.log("updatedLife for service-life", updatedLife);
      await utils.updatebykey(
        process.env.INDEXDB_SERVICEWORKER,
        "service-life",
        updatedLife,
        "life"
      );
  }
};

export const calculateWatchPoint = (points = 0, loc_points = 0) => {
  if (points) {
    const watchPoints = Number(points.totalPoints + loc_points) / 60;
    return watchPoints.toFixed(0);
  } else return 0;
};

export const calculateCipherPoints = (points = 0) => {
  if (points) {
    const cipherPoints = Number(points.totalEngagementScore) / 60;
    return cipherPoints.toFixed(0);
  } else return 0;
};

export const changem3u8Tomp4 = (src) => {
  let pathname = src.replace("/HLS/", "/MP4/");
  let pathwithext = pathname.replace(".m3u8", ".mp4");
  return pathwithext;
};

export const getThumbnailFromSrc = (src) => {
  let pathname = src.replace("/HLS/", "/Thumbnails/");
  let thumbnail1 = [];
  thumbnail1.push(pathname.replace(".m3u8", ".0000001.jpg"));
  return thumbnail1;
};

export const difftimestamp = (tstamp1, tstamp2 = Date.now()) => {
  let date1 = new Date(tstamp1);
  let date2 = new Date(tstamp2);

  const TimeStampAreOnSameDay = (d1, d2) =>
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate();

  return TimeStampAreOnSameDay(date1, date2);
};

export function difftimestampindays(prevDate, currDate = Date.now()) {
  let prev_timestamp = new Date(prevDate);
  let curr_timestamp = new Date(currDate);
  let firstDate = new Date(
    prev_timestamp.getFullYear(),
    prev_timestamp.getMonth(),
    prev_timestamp.getDate()
  );
  let secondDate = new Date(
    curr_timestamp.getFullYear(),
    curr_timestamp.getMonth(),
    curr_timestamp.getDate()
  );
  let millisecondsPerDay = 1000 * 60 * 60 * 24;
  let Difference_in_Date =
    (secondDate.getTime() - firstDate.getTime()) / millisecondsPerDay;
  return Math.round(Difference_in_Date);
}

export function getCurrVidSerial(course) {
  if (course) {
    const curr = course?.status?.seenvideos;
    const index = course?.videoList?.indexOf(curr[curr.length - 1]?.videoId);
    if (index)
      return index + 2 >= course?.videoList.length ? index + 1 : index + 2;
  }
  return 1;
}

export const calculateprogress = (enrollments, course) => {
  let completed = enrollments?.find((x) => x.courseId === course._id).seenvideos
    .length;
  let totalvideo = course.videoList.length;
  let progressPer = (completed * 100) / totalvideo;
  return progressPer.toFixed(2);
};

export function getTheme() {
  const theme = utils.getCookie("theme");
  if (theme === "") {
    const hour = new Date().getHours();
    if (hour >= 7 && hour < 19) {
      return "light";
    } else {
      return "dark";
    }
  } else {
    return theme?.mode;
  }
}

export function isvalidUrl(url) {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
    // throw new InvalidPropertyError("Valid media URL's required");
  }
}

export function formatDateValue(dateString) {
  if (dateString) {
    //   console.log("before help: ", dateString);
    const dateArr = dateString.split("/");
    let date = dateArr[0] ?? 10;
    let month = dateArr[1] ?? 10;
    let year = dateArr[2] ?? 2022;
    if (Number(date) < 10 && date?.length === 1) {
      date = "0" + date;
    }
    if (Number(month) < 10 && month?.length === 1) {
      month = "0" + month;
    }
    const resDate = `${year}-${month}-${date}`;
    return resDate;
  }
}

export const getTemplateById = (templateId) => {
  if (!templateId) return;
  switch (templateId) {
    case 1:
      return "fresher";
    case 2:
      return "experienced";
    case 3:
      return "modern";
  }
};

export const getIdByTemplate = (template) => {
  if (!template) return;
  switch (template) {
    case fresher:
      return 1;
    case "experienced":
      return 2;
    case "modern":
      return 3;
  }
};
