import client from "./_client";

const endpointV1 = "/v1/submissions";

//GET REQUEST
export const getsubmissionbyme = (videoId) =>
  client.get(endpointV1 + `?videoId=${videoId}`);

export const getcoursescore = (courseId) =>
  client.get(endpointV1 + `/score?courseId=${courseId}`);

//PATCH
export const updateassignmentsubmission = (details) =>
  client.patch(endpointV1 + `/`, details);

//POST
export const submitAssignment = (submissionInfo) =>
  client.post(endpointV1 + `/`, submissionInfo);
