import { debounce } from "lodash";

export const socketUpdateProgress = debounce((data, socket) => {
  socket?.emit("course:updateprogress", {
    userId: data.uid,
    videoId: data.vid,
    courseId: data.cid,
  });
}, 600);

export const socketUpdateProgressV2 = debounce((data, socket) => {
  socket?.emit("course:v2:updateprogress", {
    userId: data.uid,
    entityId: data.eid,
    courseId: data.cid,
  });
}, 600);

export const socketUpdateView = debounce((data, socket) => {
  socket?.emit("video:viewed", {
    videoId: data.vid,
    userId: data.uid,
  });
}, 600);

export const socketHandlePoints = debounce((data, socket) => {
  socket?.emit("watchPoints:v1:handlePoints", {
    userToken: data.userId,
    earnedPoints: data.points,
  });
}, 600);

export const socketHandleEngagement = debounce((data, socket) => {
  socket?.emit("watchPoints:v1:handleEngagement", {
    userToken: data.userId,
    engagementPoints: data.points,
  });
}, 600);

export const socketHandleCertificates = debounce((data, socket) => {
  socket?.emit("users:certificate", {
    userId: data.userId,
    entityId: data.entityId,
  });
}, 600);

export const socketCreatorReq = debounce((data, socket) => {
  socket?.emit("users:creator-request", {
    userId: data.userId,
    entityId: data.entityId,
  });
}, 600);

export const socketFeedbackSend = debounce((data, socket) => {
  socket?.emit("users:feedback", {
    userId: data.userId,
    entityId: data.entityId,
  });
}, 600);

export const socketLike = debounce((data, socket) => {
  socket?.emit("video:like", {
    userId: data.userId,
    entityId: data.entityId,
  });
}, 600);

export const socketUnLike = debounce((data, socket) => {
  socket?.emit("video:unlike", {
    userId: data.userId,
    entityId: data.entityId,
  });
}, 600);

export const socketMention = debounce((data, socket) => {
  socket?.emit("comment:mention", {
    type: data.type,
    userId: data.userId,
    entityId: data.entityId,
    commentText: data.commentText,
    recieverIds: data.recieverIds,
  });
}, 600);

export const socketReply = debounce((data, socket) => {
  socket?.emit("comment:reply", {
    type: data.type,
    userId: data.userId,
    entityId: data.entityId,
    commentText: data.commentText,
    recieverIds: data.recieverIds,
  });
}, 600);

export const socketComment = debounce((data, socket) => {
  socket?.emit("comment:create", {
    type: data.type,
    userId: data.userId,
    entityId: data.entityId,
    commentText: data.commentText,
    recieverIds: data.recieverIds,
  });
}, 600);

export const socketEnrollment = debounce((data, socket) => {
  socket?.emit("course:enrollment", {
    userId: data.userId,
    entityId: data.entityId,
  });
}, 600);

export const socketUnEnroll = debounce((data, socket) => {
  socket?.emit("course:unenrollment", {
    userId: data.userId,
    entityId: data.entityId,
  });
}, 600);

export const socketFollow = debounce((data, socket) => {
  socket?.emit("users:follow", {
    userId: data.userId,
    entityId: data.networkId,
  });
}, 600);

export const socketUnFollow = debounce((data, socket) => {
  socket?.emit("users:unfollow", {
    userId: data.userId,
    entityId: data.networkId,
  });
}, 600);
